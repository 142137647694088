import React, { useState, useEffect } from 'react'
import { Dipps, weekNumbers, currentWeekPrev } from '../../api/routes';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import './styles.css'

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

const weekDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag', 'Välj Dag']


export default function CreateDippDialogFree(props) {
    const [open, setOpen] = useState(false);
    const [newDipp, setNewDipp] = useState(true)

    const weeks = weekNumbers()

    const genDipp = () => {
        const _dipp = {
            smak: "", 
            subSmak: "",
            subSmaker: [""],    
            sort: "", type: "Singel", color: "Vit",
            back: 0,
            plat: 0,
            strut: 0,
            countOff: [],
            dag: "Okej", kommentar: "", 
            orderID: 0, 
            done: false, 
            toPralinrum: false,
            backaResten: false, backadLjus: false, backadMork: false,
            prio: 1, 
            week: currentWeekPrev(),
            year: new Date().getFullYear(),
        }
        return _dipp
    }

    const [dipp, setDipp] = useState(genDipp())

    const handleClickOpen = async () => {
        if (props.isAdmin()) {
          setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleUpdate = (name, value) => {
        const _dipp = { ...dipp }
        _dipp[name] = value
        setDipp(_dipp)
    }
    const handleUpdateDay = e => {
        const _dipp = { ...dipp }
        if(_dipp.day_index !== 7) {
            _dipp.day_index = e.target.value
            _dipp.dag = weekDays[e.target.value]
        } else {
            _dipp.day_index = 0
            _dipp.dag = weekDays[0]
        }
        setDipp(_dipp)
    }
    const handleUpdateEvent = e => {
        const { name, value } = e.target
        const _dipp = { ...dipp }
        _dipp[name] = value
        setDipp(_dipp)
    }
    const handleCreate = async () => {
        const _dipp = { ...dipp }
        _dipp.dag = weekDays[_dipp.day_index]
        
        handleClose()
        await Dipps.Create(_dipp)
    }


    const isBudskap = () => dipp.type == 'Budskap'

    const mapSwitches = () => {
      const mapSwitch = (label, name) => (
        <FormWrapper label={label}>
          <Switch
            checked={dipp[name]}
            name={name}
            onChange={e => handleUpdate(e.target.name, e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>
      )
      if(!isBudskap()) return mapSwitch("Till Pralinrum", "toPralinrum")
      return (
        <div className="flex-center">
          {mapSwitch("Backa Resten", "backaResten")}
          <div className="spacer-15" />
          {mapSwitch("Backad Ljus", "backadLjus")}
          <div className="spacer-10" />
          {mapSwitch("Backad Mörk", "backadMork")}
        </div>
      )
    }

  return (
    <div>
    <div>
        <Button onClick={handleClickOpen} variant="outlined" color="primary">skapa doppning +</Button>
    </div>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Planera en Doppning</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <div className="flex">
            <TextField
              id="standard-basic" label="Smak" variant="standard"
              onChange={handleUpdateEvent}
              value={dipp.smak}
              name="smak"
              InputProps={{ readOnly: false, }}
            />

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Typ</InputLabel>
              <NativeSelect
                value={dipp.sort}
                name="sort"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'sort', id: 'uncontrolled-native', }}
              >{['Sort', 'Tryffel', 'Nougat', 'Kola', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Singel/Budskap</InputLabel>
              <NativeSelect
                value={dipp.type}
                name="type"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'type', id: 'uncontrolled-native', }}
              >{['Singel', 'Budskap'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Doppas i</InputLabel>
              <NativeSelect
                value={dipp.color}
                name="color"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'color', id: 'uncontrolled-native', }}
              >{['Vit', 'Ljus', 'Mörk', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Back</InputLabel>
              <NativeSelect
                value={dipp.back}
                name="back"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'back', id: 'uncontrolled-native', }}
              >{[...Array(30).keys()].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>
            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Plåt</InputLabel>
              <NativeSelect
                value={dipp.plat}
                name="plat"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'plat', id: 'uncontrolled-native', }}
              >{[...Array(30).keys()].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>
            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Strut</InputLabel>
              <NativeSelect
                value={dipp.strut}
                name="strut"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'strut', id: 'uncontrolled-native', }}
              >{[...Array(30).keys()].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Planerad Dag</InputLabel>
              <NativeSelect
                value={dipp.day_index}
                name="day_index"
                onChange={handleUpdateDay}
                inputProps={{ name: 'day_index', id: 'uncontrolled-native', }}
              >{[7, 0, 1, 2, 3, 4, 5, 6].map(item => <option key={item} value={item}>{weekDays[item]}</option>)}
              </NativeSelect>
            </FormControl>
            <div className="spacer-5" />

            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Vecka</InputLabel>
              <NativeSelect
                value={dipp.week}
                name="week"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'week', id: 'uncontrolled-native', }}
              >{weeks.map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">År</InputLabel>
              <NativeSelect
                value={dipp.year}
                name="year"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'year', id: 'uncontrolled-native', }}
              >{['2023', '2024', '2025'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>
          </div>

          <div className="spacer-5" />

          <div className="flex">
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Prio</InputLabel>
              <NativeSelect
                value={dipp.prio}
                name="prio"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'prio', id: 'uncontrolled-native', }}
              >{[[1, 'Låg'], [2, 'Medel'], [3, 'Hög']].map(item => <option key={item[0]} value={item[0]}>{item[1]}</option>)}
              </NativeSelect>
            </FormControl>
            <div className="spacer-5" />

            {mapSwitches()}
            <div className="spacer-10" />

            <TextField
              id="standard-basic" label="Kommentar" variant="standard"
              onChange={handleUpdateEvent}
              value={dipp.kommentar}
              name="kommentar"
              onChange={handleUpdateEvent}
              multiline
              maxrows={4}
            />
            <div className="spacer-5" />

          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
          <Button color="primary" onClick={handleCreate}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
