import { supabase } from "./supabase"

export const weekdays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag']

const alertError = async (error) => {
  console.log(error)
  if (error.message === 'JWSError JWSInvalidSignature') await supabase.auth.signOut()
  alert("Fel inträffade. Kontrollera nätverksanslutning. Vid upprepade fel kontakta support.")
}

export const Test = {
  Create: async (data) => { 
    const { res, error } = await supabase.from('fortknox').insert([{valued:0}])
    if (error) alertError(error)
    console.log(res)
  },
}



export const day_index = () => {
  let date = new Date();
  let day = date.getDay();
  if (day == 0) { // weekday 0 (sunday) is 7 in european
    day = 7;
  }
  return day
}

export const getTimestamp = () => {
  const date = new Date()
  const getZero = item => String(item).length < 2 ? `0${item}` : item
  const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
  const time = getZero(date.getHours()) + ':' + getZero(date.getMinutes()) + ':' + getZero(date.getSeconds()) + '.000Z'

  const full = year + 'T' + time
  return full
}

export const currentWeekPrev = () => {
  var currentdate = new Date();
  var oneJan = new Date(currentdate.getFullYear(),0,1);
  var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
  // result = result - 1
  result = result > 0 ? result : 1
  result = result > 52 ? 1 : result
  return result
}

export const currentWeek = () => {
  var currentdate = new Date();
  var oneJan = new Date(currentdate.getFullYear(),0,1);
  var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
  return result
}

export const weekNumbers = () => {
  var current = 1
  const weeks = []
  const arr = [...Array(52).keys()] // [1,2,3]
  arr.map(item => {
    var _week = current + item
    if (_week > 52) weeks.push(_week - 52)
    else weeks.push(_week)
  })
  return weeks
}


export const getDate = () => {
  const date = new Date()
  const getZero = item => String(item).length < 2 ? `0${item}` : item
  const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
  return year
}

export const getDatesList = (trackingFrom, trackingTo) => {
  var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
  const parse = d => ((d.getMonth() > 8) ? (d.getMonth() + 1) : ((d.getMonth() + 1))) + '/' + ((d.getDate() > 9) ? d.getDate() : (d.getDate())) + '/22'
  var trackingFromToList = getDaysArray(new Date(trackingFrom), new Date(trackingTo));
  return trackingFromToList.map(item => parse(item))
}

export const SearchDocuments = async (searchProps) => {
  // fetch information from dbs that match props
  // const searchProps = {
  //   smak: "",
  //   ingrediens: "",
  //   information: "",
  //   datum: "",
  //   signatur: "",
  // }
  const {smak, ingrediens, information, datum, signatur} = searchProps

  var data = {}
  var cooks = []
  var dipps = []
  var frys = []

    const _cooks = await Tracking_Recept.ReadParsed()
    _cooks.map(item => {

      
      var _smak = item.cook.smak.toLowerCase()
      var _signatur = item.sign.toLowerCase()
     
      if (
        _smak.includes(smak) && 
        _signatur.includes(signatur) &&
        item.date.includes(datum) &&
        item.steps.find(step => {
          var name = step.name.toLowerCase()
          return name.includes(ingrediens)
        }) && 
        item.steps.find(step => {
          var info = step.info.toLowerCase()
          return info.includes(information)
        })
      ) cooks.push(item)
    })
  
    const _dipps = await Tracking_Recept_Dipp.Read()
    _dipps.map(item => {
      
      var _smak = item.dipp.smak.toLowerCase()
      if (
        _smak.includes(smak) &&
        item.sign && item.sign.find(sign => {
          var _sign = sign.toLowerCase()
          return _sign.includes(signatur)
        }) &&
        item.date && item.date.find(date => {
          var _date = date.toLowerCase()
          return _date.includes(datum)
        }) &&
        item.trackingRecept.steps && item.trackingRecept.steps.map(step => {
          var _name = step.name.toLowerCase()
          return _name.includes(ingrediens)
        }) &&
        item.trackingRecept.steps && item.trackingRecept.steps.map(step => {
          var _info = step.info.toLowerCase()
          return _info.includes(information)
        })
      ) dipps.push(item)

      /*
      //if (signatur !== "" && item.sign && item.sign.find(sign => {
      //  var _sign = sign.toLowerCase()
      //  return _sign.includes(signatur)
      })) dipps.push(item)

      if (datum !== "" && item.date && item.date.find(date => {
        var _date = date.toLowerCase()
        return _date.includes(datum)
      })) dipps.push(item)

      if (ingrediens !== "" && item.trackingRecept.steps && item.trackingRecept.steps.map(step => {
        var _name = step.name.toLowerCase()
        return _name.includes(ingrediens)
      })) dipps.push(item)

      if (information !== "" && item.trackingRecept.steps && item.trackingRecept.steps.map(step => {
        var _info = step.info.toLowerCase()
        return _info.includes(information)
      })) dipps.push(item)
      */

    })
  

  // remove duplicates

  return {cooks, dipps}
}

export const FortKnox = {
  Create: async () => { 
    const { data, error } = await supabase.from('fortknox').insert([{value:0}])
    if (error) alertError(error)
  },
}

export const OrdersHistory = {
  Create: async order => { 
    const {id, ..._order} = order
    _order.timestamp = getTimestamp()
    const { data, error } = await supabase.from('orders_history').insert([_order])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: orders, error } = await supabase.from('orders_history').select('*')
    if (error) alertError(error)
    return orders
  },
}

export const Orders = {
  Create: async order => { 
    const { data, error } = await supabase.from('orders').insert([order])
    if (error) alertError(error)
  },
  CreateMany: async orders => { 
    const { data, error } = await supabase.from('orders').insert(orders)
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: orders, error } = await supabase.from('orders').select('*')
    if (error) alertError(error)
    return orders
  },
  ReadColumnByID: async (col, id) => {
    let { data: orders, error } = await supabase.from('orders').select('*').select(col).eq('id',id)
    if (error) alertError(error)
    return orders
  },
  Update: async order => {
    const { data, error } = await supabase.from('orders').update(order).eq('id', order.id)
    if (error) alertError(error)
    await OrdersHistory.Create(order)
  },  
  Delete: async order => {
    const { data, error } = await supabase.from('orders').delete().eq('id', order.id)
    if (error) alertError(error)
  },  
  Deselect: async () => {
    const { data, error } = await supabase
    .from('orders')
    .update({ showMark: false })
    .eq('showMark', true)
    if (error) alertError(error)
  }, 
  UpdateCols: async () => {
    const { data, error } = await supabase
    .from('orders')
    .update({ subSmaker: ["","","","",""] })
    if (error) alertError(error)
  }, 
}

export const OrdersChecklist = {
  Create: async check => { 
    const { data, error } = await supabase.from('ordersChecklist').insert([check])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: ordersChecklist, error } = await supabase.from('ordersChecklist').select('*')
    if (error) alertError(error)
    return ordersChecklist
  },
  ReadLast: async () => {
    let { data: ordersChecklist, error } = await supabase.from('ordersChecklist').select('*')
    if (error) alertError(error)
    return ordersChecklist[ordersChecklist.length -1]
  },
}


export const Persons = {
  Create: async person => { 
    const { data, error } = await supabase.from('persons').insert([person])
    if (error) alertError(error)
  },
  Read: async (all = false) => {
    if (all) {
      let { data: persons, error } = await supabase.from('persons').select('*')
      if (error) alertError(error)
      return persons
    } 
    let { data: persons, error } = await supabase.from('persons').select('*').eq('active', true)
    if (error) alertError(error)
    return persons
  },
  Update: async person => {
    const { data, error } = await supabase.from('persons').update(person).eq('id', person.id)
    if (error) alertError(error)
  }, 
  Upsert: async persons => {
    const { data, error } = await supabase
    .from('persons')
    .upsert(persons)
    if(error) alertError(error)
  },
  Delete: async person => {
    const { data, error } = await supabase.from('persons').delete().eq('id', person.id)
    if (error) alertError(error)
  },  
}

export const TrackingOrder = {
  Create: async (smak, trackingFrom, trackingValue) => { 
    var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
    const trackingTo = new Date()
    var trackingFromToList = getDaysArray(new Date(trackingFrom), trackingTo);
    
    const obj = {smak, trackingFrom, trackingTo, trackingValue, trackingFromToList}
    const { data, error } = await supabase.from('trackingOrder').insert(obj)
    if (error) alertError(error)
  },
}

export const Cooks = {
  Create: async cook => { 
    // const currentYear = new Date().getFullYear()
    // cook.year = currentYear
    const { data, error } = await supabase.from('cooks').insert([cook])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: cooks, error } = await supabase.from('cooks').select('*')
    if (error) alertError(error)
    return cooks
  },
  ReadByOrderID: async orderID => {
    let { data: cooks, error } = await supabase.from('cooks').select('*').eq('orderID', orderID)
    if (error) alertError(error)
    return cooks
  },
  Update: async cook => {
    const { data, error } = await supabase.from('cooks').update(cook).eq('id', cook.id)
    if (error) alertError(error)
  },  
  Upsert: async cooks => {
    const { data, error } = await supabase
    .from('cooks')
    .upsert(cooks)
    if(error) alertError(error)
    return data
  },
  Delete: async cook => {
    const { data, error } = await supabase.from('cooks').delete().eq('id', cook.id)
    if (error) alertError(error)
  },  
}

export const Dipps = {
  Create: async dipp => { 
    // const currentYear = new Date().getFullYear()
    // dipp.year = currentYear
    const { data, error } = await supabase.from('dipps').insert([dipp])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: dipps, error } = await supabase.from('dipps').select('*')
    if (error) alertError(error)
    return dipps
  },
  ReadByID: async id => {
    let { data: dipps, error } = await supabase.from('dipps').select('*').eq('id', id)
    if (error) alertError(error)
    return dipps
  },
  ReadByOrderID: async orderID => {
    let { data: dipps, error } = await supabase.from('dipps').select('*').eq('orderID', orderID)
    if (error) alertError(error)
    return dipps
  },
  Update: async dipp => {
    const { data, error } = await supabase.from('dipps').update(dipp).eq('id', dipp.id)
    if (error) alertError(error)
  },  
  Upsert: async dipps => {
    const { data, error } = await supabase
    .from('dipps')
    .upsert(dipps)
    if(error) alertError(error)
    return data
  },
  Delete: async dipp => {
    const { data, error } = await supabase.from('dipps').delete().eq('id', dipp.id)
    if (error) alertError(error)
  },  
}

export const Recepts = {
  Create: async recept => { 
    const { data, error } = await supabase.from('recepts').insert([recept])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: recepts, error } = await supabase.from('recepts').select('*')
    if (error) alertError(error)
    return recepts
  },
  ReadById: async (_id) => {
    let { data: recept, error } = await supabase.from('recepts').select('*').eq('id', _id)
    if (error) alertError(error)
    return recept
  },
  Update: async recept => {
    const { data, error } = await supabase.from('recepts').update(recept).eq('id', recept.id)
    if (error) alertError(error)
  },  
  Delete: async recept => {
    const { data, error } = await supabase.from('recepts').delete().eq('id', recept.id)
    if (error) alertError(error)
  },  
}

export const Frys = {
  Create: async fry => { 
    const { data, error } = await supabase.from('frys').insert([fry])
    if (error) alertError(error)

    let log = JSON.stringify(fry)
    const { data2, error2 } = await supabase
    .from('frys_history')
    .insert([
      { log : log},
    ])
  },
  Read: async () => {
    let { data: frys, error } = await supabase.from('frys').select('*')
    if (error) alertError(error)
    return frys
  },
  ReadVar: async (showDone) => {
    let { data: frys, error } = await supabase.from('frys').select('*').eq('done', showDone)
    if (error) alertError(error)
    return frys
  },
  Update: async fry => {
    const { data, error } = await supabase.from('frys').update(fry).eq('id', fry.id)
    if (error) alertError(error)

    let log = JSON.stringify(fry)
    const { data2, error2 } = await supabase
    .from('frys_history')
    .insert([
      { log : log},
    ])
  },  
  Delete: async fry => {
    const { data, error } = await supabase.from('frys').delete().eq('id', fry.id)
    if (error) alertError(error)
  },  
}

export const Planner = {
  Create: async plan => { 
    const { data, error } = await supabase.from('planner').insert([plan])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: planner, error } = await supabase.from('planner').select('*')
    if (error) alertError(error)
    return planner
  },
  Update: async plan => {
    const { data, error } = await supabase.from('planner').update(plan).eq('id', plan.id)
    if (error) alertError(error)
  },  
  Delete: async plan => {
    const { data, error } = await supabase.from('planner').delete().eq('id', plan.id)
    if (error) alertError(error)
  },  
}

export const WhiteboardContent = {
  Read: async () => {
    let { data: whiteboard, error } = await supabase.from('whiteboard').select('*')
    if (error) alertError(error)
    return whiteboard
  },
  Update: async item => {
    const { data, error } = await supabase.from('whiteboard').update(item).eq('id', item.id)
    if (error) alertError(error)
  },  
}

export const Ingredienser = {
  Create: async item => { 
    const { data, error } = await supabase.from('ingredienser').insert([item])
    if (error) alertError(error)
  },
  CreateMany: async items => { 
    const _items = items.map(item => {
      item.new = false
      return item
    })
    console.log(_items)
    const { data, error } = await supabase.from('ingredienser').insert(_items)
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: ingredienser, error } = await supabase.from('ingredienser').select('*')
    if (error) alertError(error)
    return ingredienser
  },
  ReadById: async (_id) => {
    let { data: item, error } = await supabase.from('ingredienser').select('*').eq('id', _id)
    if (error) alertError(error)
    return item
  },
  Update: async item => {
    const { data, error } = await supabase.from('ingredienser').update(item).eq('id', item.id)
    if (error) alertError(error)
  },  
  Upsert: async items => {
    const _update = async item => {
      const { data, error } = await supabase.from('ingredienser').update(item).eq('name', item.name)
      if (error) alertError(error)
    }
    items.map(item => _update(item))
  },  
  Delete: async item => {
    const { data, error } = await supabase.from('ingredienser').delete().eq('id', item.id)
    if (error) alertError(error)
  },  
}

export const Tracking_Recept = {
  Create: async item => { 
    const { data, error } = await supabase.from('tracking_recept').insert([item])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: tracking_recept, error } = await supabase.from('tracking_recept').select('*')
    if (error) alertError(error)
    return tracking_recept
  },
  ReadParsed: async () => {
    let { data: tracking_recept, error } = await supabase.from('tracking_recept').select('*')
    if (error) alertError(error)
    const tracking_recept_parsed = tracking_recept.map(item => {
      const _item = {...item}
      _item.cook = JSON.parse(_item.cook)
      _item.recept = JSON.parse(_item.recept)
      return _item
    })
    return tracking_recept_parsed
  },
  ReadById: async (_id) => {
    let { data: item, error } = await supabase.from('tracking_recept').select('*').eq('id', _id)
    if (error) alertError(error)
    return item
  },
  ReadByCookId: async (_id) => {
    let { data: item, error } = await supabase.from('tracking_recept') 
    .select('*').eq('cook_id', _id)
    if (error) alertError(error)
    return item
  },
  Update: async item => {
    const { data, error } = await supabase.from('tracking_recept').update(item).eq('id', item.id)
    if (error) alertError(error)
  },  
  Delete: async item => {
    const { data, error } = await supabase.from('tracking_recept').delete().eq('id', item.id)
    if (error) alertError(error)
  },  
}

export const Tracking_Recept_Dipp = {
  Create: async item => { 
    // if item.trackingRecept is an empty object, don't insert
    if (Object.keys(item.trackingRecept).length === 0) return
    const { data, error } = await supabase.from('tracking_recept_dipp').insert([item])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: tracking_recept_dipp, error } = await supabase.from('tracking_recept_dipp').select('*')
    if (error) alertError(error)
    return tracking_recept_dipp
  },
  ReadById: async (_id) => {
    let { data: item, error } = await supabase.from('tracking_recept_dipp').select('*').eq('id', _id)
    if (error) alertError(error)
    return item
  },
  ReadByDippId: async (_id) => {
    let { data: item, error } = await supabase.from('tracking_recept_dipp') 
    .select('*').eq('dipp_id', _id)
    if (error) alertError(error)
    return item
  },
  Update: async item => {
    const { data, error } = await supabase.from('tracking_recept_dipp').update(item).eq('id', item.id)
    if (error) alertError(error)
  },  
  Delete: async item => {
    const { data, error } = await supabase.from('tracking_recept_dipp').delete().eq('id', item.id)
    if (error) alertError(error)
  },  
}

export const Tracking_Pack = {
  Create: async item => { 
    const { data, error } = await supabase.from('tracking_pack').insert([item])
    if (error) alertError(error)
  },
  Read: async () => {
    let { data: tracking_pack, error } = await supabase.from('tracking_pack').select('*')
    if (error) alertError(error)
    return tracking_pack
  },
  ReadById: async (_id) => {
    let { data: item, error } = await supabase.from('tracking_pack').select('*').eq('id', _id)
    if (error) alertError(error)
    return item
  },
  Update: async item => {
    const { data, error } = await supabase.from('tracking_pack').update(item).eq('id', item.id)
    if (error) alertError(error)
  },  
  Delete: async item => {
    const { data, error } = await supabase.from('tracking_pack').delete().eq('id', item.id)
    if (error) alertError(error)
  },  
}

export const Butik = {
  Lager: {
    Create: async item => { 
      const { data, error } = await supabase.from('butikslager').insert([item])
      if (error) alertError(error)
    },
    CreateHistory: async item => { 
      const {id, ...rest} = item
      const { data, error } = await supabase.from('butikslager_historik').insert([rest])
      if (error) alertError(error)
    },
    CreateManyHistory: async items => { 
      const _items = items.map(item => {
        const {id, ...rest} = item
        return rest
      })
      const { data, error } = await supabase.from('butikslager_historik').insert(_items)
      if (error) alertError(error)
    },
    Read: async () => {
      let { data: butikslager, error } = await supabase.from('butikslager').select('*')
      if (error) alertError(error)
      return butikslager
    },
    ReadHistory: async () => {
      let { data: butikslager, error } = await supabase.from('butikslager_historik').select('*')
      if (error) alertError(error)
      return butikslager
    },
    ReadById: async (_id) => {
      let { data: item, error } = await supabase.from('butikslager').select('*').eq('id', _id)
      if (error) alertError(error)
      return item
    },
    Update: async item => {
      const { data, error } = await supabase.from('butikslager').update(item).eq('id', item.id)
      if (error) alertError(error)
      await Butik.Lager.CreateHistory(item)
    },  
    Upsert: async items => {
      const { data, error } = await supabase
      .from('butikslager')
      .upsert(items)
      if(error) alertError(error)
    },
    Delete: async item => {
      const { data, error } = await supabase.from('butikslager').delete().eq('id', item.id)
      if (error) alertError(error)
    },  
  }
}

export const Routes = {
  Create: async (item, table) => { 
    const { data, error } = await supabase.from(table).insert(Array.isArray(item) ? item : [item])
    if (error) alertError(error)
    return data
  },
  Read: async (table) => {
    let { data: tracking_pack, error } = await supabase.from(table).select('*')
    if (error) alertError(error)
    return tracking_pack
  },
  Find: async (value, column, table) => {
    let lowercaseValue = value.toLowerCase()
    let { data: item, error } = await supabase.from(table).select('*').eq(column, lowercaseValue)
    if (error) alertError(error)
    return item
  },
  Update: async (item, table) => {
    const { data, error } = await supabase.from(table).update(item).eq('id', item.id)
    if (error) alertError(error)
    return data
  },  
  Upsert: async (items, table) => {
    const { data, error } = await supabase.from(table).upsert(items)
    if(error) alertError(error)
    return data
  },
  Delete: async (item, table) => {
    const { data, error } = await supabase.from(table).delete().eq('id', item.id)
    if (error) alertError(error)
    return data
  },  
}



/*

*/
export const aqueduct = async () => {
  let { data, error } = await supabase.from('aqueduct_settings').select('*')
  if (error) alertError(error)

  let { data: orders, error2 } = await supabase.from('aqueduct_orders').select('*')
  if (error2) alertError(error2)

  console.log(orders)

  return { data: data[0], orders }
}